<template>
    <div class="list">
        <div class="map">
            <div id="container"></div> 
        </div>
        <div class="title">设备列表</div>
        <div class="content">
            <div v-if="loading">
                <van-loading type="spinner" vertical>加载中</van-loading>
            </div>
            <div v-else>
                <div v-for="(item,index) in devList" :key="index" @click="enter(item)" class="inline-block">
                    <div :class="{'info':true,'info-online':item.devStatus === 'online','info-alarm':item.devStatus === 'alarm'}" >
                        <div v-if="item.sceneType === 'level' && item.wlmsStatus && item.wlmsStatus.waterLevel !== null">相对水位：<span>{{item.wlmsStatus ? item.wlmsStatus.waterLevel : 0}}m</span></div>
                        <div v-if="item.sceneType === 'level' && item.wlmsStatus && item.wlmsStatus.absoluteLevel !== null">绝对水位：<span>{{item.wlmsStatus ? item.wlmsStatus.absoluteLevel : 0}}m</span></div>
                        <div v-if="(item.sceneType === 'level' || item.sceneType === 'water') && item.wlmsStatus && item.wlmsStatus.waterDeep !== null">{{item.sceneType === 'water' ? '积水深度' :'当前水深'}}：<span>{{item.wlmsStatus ? item.wlmsStatus.waterDeep : 0}}{{item.sceneType === 'water' ? 'cm' :'m'}}</span></div>
                        <div>{{item.sceneType === 'range' ? '测量距离' : '水面距离'}}：<span>{{item.wlmsStatus ? item.wlmsStatus.curDist : 0}}m</span></div>
                    </div>
                    <div :class="{'bottom-block':true,'online-dev':item.devStatus === 'online','alarm-dev':item.devStatus === 'alarm'}">
                        <div>设备名称：{{ item.devName || '未设置'}}</div>
                        <div>设备编号：{{ item.devCode }}</div>
                        <div>安装位置：{{ item.location || '未设置'}}</div>
                        <div>更新时间：{{ item.lastUpdateTime || '0000-00-00 00:00:00'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <tabbar />
    </div>
</template>

<script>
import tabbar from "@/components/wlms/tabbar.vue";
import iconMark from '@/assets/wlms/img/marker.png'

export default {
    components: {
        tabbar,
    },
    data(){
        return{
            map:null,
            projectCode:null,
            devList:[],
            loading:true
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem("projectCode");
        this.createMap();
    },
    methods:{
        enter(item){
            this.$router.push({path:'/wlms/home',query:{devCode:item.devCode}})
        },
        createMap(){
            this.map = new AMap.Map('container',{
                zooms:[4,19], //设置缩放级别范围 4-19 级
                mapStyle: 'amap://styles/blue', //设置地图的显示样式
            });
            AMap.plugin(['AMap.ToolBar','AMap.Scale'],() => {//异步同时加载多个插件
                var toolbar = new AMap.ToolBar();
                var scale = new AMap.Scale();
                this.map.addControl(toolbar);
                this.map.addControl(scale);
            });
            this.getDevList();
        },
        getDevList(){
            return new Promise( () => {
                this.$api.WLMS.deviceMonitorInfo({
                    projectCode:this.projectCode
                }).then( d => {
                    this.devList = d;
                    this.loading = false;
                    this.devList.forEach( e => {
                        const gps = [e.lon,e.lat]
                        if(e.lon || e.lat){
                            this.gpsTransformGaoDe(gps).then( d => {
                                e.GaoDelnglat = d;
                                e.lon = d.lng;
                                e.lat = d.lat;
                            })
                        }
                    })
                    
                    // 异步绘制-防止高德异步函数导致的加载失败问题
                    setTimeout(()=>{
                        this.updateMapMarker(this.devList);
                    },1500)
                })
            })
        },
        // gps 转 高德坐标系
        gpsTransformGaoDe(gps){
            return new Promise( resolve => {
                new AMap.convertFrom(gps, 'gps', (status, result) => {
                    if (result.info === 'ok') {
                        resolve(result.locations[0])
                    }
                })
            })
        },
        updateMapMarker(data){
            let markArr =[];
            var devIcon = new AMap.Icon({
                size: new AMap.Size(22, 35),
                image: iconMark,
                imageSize: new AMap.Size(22, 35),
                imageOffset: new AMap.Pixel(0, 0)
            });
            for(let i=0;i<data.length;i++){
                if(data[i].lon && data[i].lat){
                    var marker = new AMap.Marker({
                        position: [data[i].lon,data[i].lat],
                        offset: new AMap.Pixel(-10, -36),
                        icon: devIcon, // 添加 Icon 图标 URL 
                        label:{
                            offset: new AMap.Pixel(0, -2),  //设置文本标注偏移量
                            content: data[i].devStatus === 'online' ? "<span class='online'>"+data[i].devName+"</span>" :data[i].devStatus === 'alarm'? "<span class='alarm'>"+data[i].devName+"</span>" : "<span class='offline'>"+data[i].devName+"</span>",
                            direction: 'top' //设置文本标注方位
                        }
                    });
                    marker.on('click',(e) => {
                        this.$router.push({path:'/wlms/home',query:{devCode:data[i].devCode}})
                    });
                    markArr.push(marker);
                }
            }
            this.map.add(markArr);
            let position = [data[0].lon, data[0].lat]; 
            if(data[0].lon || data[0].lat){
                this.map.setCenter(position); 
                this.map.setZoom(12);
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#container {
    width:100%; height: 18rem;
}
.list{
    text-align: left;
    .title{
        padding-left: 10px;
        border-left: 4px solid #15bfc7;
        font-weight: bolder;
        font-size: .95rem;
        margin: 10px 20px 10px 20px;
    }
    .content{
        padding: 5px 20px;
        max-height: 46vh;
        overflow-y: auto;
        padding-bottom: 8vh;
        .inline-block{
            margin-bottom: 10px;
            .bottom-block{
                background-color: #a0a0a0;
                padding: 10px;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                font-size: .85rem;
                color: #fff;
                div{
                    margin-bottom:6px;
                }
            }
            .online-dev{
                background-color: #15bfc7;
            }
            .alarm-dev{
                background-color: #de8383;
            }
            .info{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                padding: 8px 10px 4px 10px;
                font-size: 0.85rem;
                background: linear-gradient(#cccccc 0%, #717171 85%);
                color: #fff;
                div{
                    width: 50%;
                    margin-bottom: 6px;
                }
                // div:nth-child(even){
                //     text-align: right;
                // }
                span{
                    font-weight: bolder;
                    color: #000;
                }
            }
            .info-online{
                background: linear-gradient(#99d2e6 0%, #339da2 85%);
            }
            .info-alarm{
                background: linear-gradient(#ffc6c0 0%, #a72929 85%);
            }
        }
        
    }
}
</style>